<template>
    <div>
        <van-nav-bar title="历史收益" left-arrow left-text="返回" @click-left="onClickLeft" />
        <div class="home_content" v-if="monthData.length">
            <van-collapse v-model="activeName" accordion>
                <van-collapse-item :title="item.settle_year_month" :name="index+1" v-for="(item,index) in monthData" :key="index">
                    月度订单交易额：{{ item.order_money/100 }}元，月度分销提成金额：{{ item.agent_money/100 }}元
                </van-collapse-item>
            </van-collapse>
        </div>
        <van-empty v-if="!monthData.length" description="暂无数据" />
    </div>
</template>
<script>
import { onBeforeMount, ref } from 'vue';
import { AgentBenefitCollect } from "../../api/agent";
export default {
    setup() {
        const onClickLeft = () => history.back();
        onBeforeMount(() => {
            loadData()
        });
        const loadData = ()=>{
            AgentBenefitCollect({phone:localStorage.getItem("agentPhone")}).then((res)=>{
                if(res){
                    monthData.value = res.items
                }
            })
        };
        const monthData = ref([]);
        return{
            onClickLeft,
            monthData,
        }
    },
}
</script>